.personal-information__photo-carnet {
  max-width: 275px;
  position: absolute;
  border-top-left-radius: 45px;
  border-top-right-radius: 95px;
  border-bottom-right-radius: 90px;
  border-bottom-left-radius: 60px;
  z-index: 1;
  top: 0;
  left: 0;
  margin-top: 70px;
  margin-left: 150px;
}