.personal-information__icon-location {
  position: relative;
  left: -95px;
  top: -40px;
  font-size: 50px;
  color: $primary;
  p {
    color: $font-color-primary;
    font-size: 20px;
    font-weight: bold;
  }

}