.experience__content-titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    label {
        font-size: 30px;
    }

    span {
        font-size: 18px;
    }
}