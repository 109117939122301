.sidebar__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  
  color: white;

  label {
    margin-top: 20px;
    cursor: pointer;
  }
}