.sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  display: flex;
  justify-content: end;
}

@media (max-width: 801px) {
  .sidebar {
    .sidebar__content {
      width: 50%;
    }
  }
}

@media (max-width: 801px) {
  .sidebar {
    .sidebar__content {
      width: 100%;
    }
  }
}