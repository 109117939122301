.paper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

@for $i from 1 through 5 {
  .paper--elevation-#{$i} {
    @include elevation($i)
  }
}

