* {
  font-family: "Roboto Condensed", sans-serif;
  margin: 0px;
}

html,
body {
  height: 100vh;
  width: 100vw;
}

main {
  width: 100%;
}
