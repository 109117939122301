.experiences {
    width: 650px;
}

@media screen and (max-width: 700px) {
    .experiences {
      width: 100%;
    }
    .experience__content-titles {
      label {
          font-size: 20px;
      }
  
      span {
          font-size: 18px;
      }
  }
}