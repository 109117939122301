.navbar__content-first {
  display: flex;
  
  label {
    margin-right: 30px;
    color: white;
    font-size: 20px;
    cursor: pointer;

    
  }

  label:hover {
    font-weight: bold;
  }
}