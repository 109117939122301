.navbar__content {
  max-width: 1344px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px 0 30px;
}

@media screen and (max-width: 700px) {
  .navbar__content {
    justify-content: end;
  }
}
